import classes from "./Loader.module.css";

export default function Loader(){
    return (
        <div className={classes.loader}>
            <div className={classes.one}></div>
            <div className={classes.two}></div>
            <div className={classes.three}></div>
        </div>
    )
}