import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import it from 'date-fns/locale/it'; //import italian locale

export default function UiDataTimePicker() {
  const [selectedDate, setSelectedDate] = useState(new Date());

  return (
    <div className="filter-widget">
      <DatePicker
        selected={selectedDate}
        onChange={setSelectedDate}
        locale={it} // set italian locale
        dateFormat="dd/MM/yyyy"
        timeCaption="Time"
        timeZone='Europe/Rome'
        className="form-control datetimepicker"
      />
    </div>
  );
}
