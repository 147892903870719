import { useEffect, useState } from "react";
import UiRadio from "../UiRadio/UiRadio";


export default function UiGroupRadio({title, name, values, onChange}){

    return <div>
        {title}
        {
            values.map((radio,idx) =>{
                return  <UiRadio
                key={idx}
                name = {name}
                id = {radio.id}
                label = {radio.label}
                description = {radio.description}
                value= {radio.value}
                onChange={onChange}
            />
            })
        }
    </div>
}