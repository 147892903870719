import { Link } from "react-router-dom";

import { AuthContext } from "../../../Contexts/Auth"

import { useContext } from "react";

export default function Navbar(){

    const {user, logout} = useContext(AuthContext)
    
    return <nav className="navbar navbar-expand-lg header-nav">
    <div className="navbar-header">
      <a id="mobile_btn" href="/">
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </a>
      <a href="/" className="navbar-brand logo">
        <img src="https://cmg.centro-assistenza-informatica.it/assets/img/logo.png" className="img-fluid" alt="Logo" />
      </a>
    </div>
    <div className="main-menu-wrapper">
      <div className="menu-header">
        <a href="/" className="menu-logo">
          <img src="https://cmg.centro-assistenza-informatica.it/assets/img/logo.png" className="img-fluid" alt="Logo" />
        </a>
        <span id="menu_close" className="menu-close">
          <i className="fas fa-times" />
        </span>
      </div>
      <ul className="main-nav">
        <li>
          <Link to="/" className="react_menu_close" >Home</Link>
        </li>
        <li>
          <Link to="/search/1" className="react_menu_close" >Medici</Link>
        </li>

        {
            !user && <li>
              <Link to="/sign" className="react_menu_close">Accedi</Link>
            </li>
        }

        {
            user && <>               
                 
                <li>
                  <Link to="/appointments" className="react_menu_close">Appuntamenti</Link>
                </li>
                
                <li>
                  <Link to="/" onClick={logout} className="react_menu_close">Esci</Link>
                </li>
                               
            </>
        }
  
        {/*
        <li>
          <Link to="/custom-form" className="react_menu_close" >Form</Link>
        </li>
        */}

      </ul>
    </div>
    <ul className="nav header-navbar-rht">
      <li className="nav-item contact-item">
        <div className="header-contact-img">
          <i className="far fa-hospital" />
        </div>
        <div className="header-contact-detail">
          <p className="contact-header">CHIAMA ORA</p>
          <p className="contact-info-header">070 491244</p>
        </div>
      </li>
    </ul>
  </nav>
}