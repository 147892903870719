import { useState } from "react";

const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue)

    const heandleChange = (event) => {
        setValue(event.target.value)
    }

    return{
        value,
        onChange : heandleChange
    }
}

export default useInput