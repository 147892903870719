export default function UiSelect( {id, label, value, onChange, values} ){

    return <div className="form-group">
        <label htmlFor={id}>
            {label}
        </label>
        <div>
            <select 
                className="form-control" 
                id={id} 
                name={id} 
                defaultValue={value}
                onChange={onChange}
            >
            {
                values.map((val,idx)=>{
                    return <option key={idx} value={val.id}>{val.name}</option>
                })
            }    
            </select>
        </div>       
    </div>
}