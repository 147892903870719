import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ConfigContext } from "../../../Contexts/Config";
import ProfileSidebar from "../../ProfileSidebar/ProfileSidebar";

export default function Appointment(){

    let {api_urls } = useContext(ConfigContext)

    let {id} = useParams()

    const [appointment, setAppointment] = useState(null)

    let shouldLog = useRef(true)

    useEffect(() => {
    
      if (shouldLog.current){
        shouldLog.current = false

        fetch(`${api_urls.cmg}/appointments/${id}`)
        .then(response => response.json())
        .then((data) => {

            //console.log(data)

            setAppointment(data)
        })
      }
      
    }, []);

    return <div className="content">
    <div className="container-fluid">
      <div className="row">
        {/* Profile Sidebar */}
        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
          <ProfileSidebar/>
        </div>
        {/* / Profile Sidebar */}
        <div className="col-md-7 col-lg-8 col-xl-9">
          <div className="card">
            <div className="card-body pt-0">
              {/* Tab Menu */}
              <nav className="user-tabs mb-4">
                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                  <li className="nav-item">
                    <a className="nav-link active" href="#pat_appointments" data-toggle="tab">Appuntamento</a>
                  </li>
                </ul>
              </nav>
              {/* /Tab Menu */}
              {/* Tab Content */}
              <div className="tab-content pt-0">
                {/* Appointment Tab */}
                <div id="pat_appointments" className="tab-pane fade show active">
                {
                appointment &&  (
                  <div className="card card-table mb-0">
                    <div className="card-body">
                      <div className="doc-info-cont">
                        <h4 className="doc-name text-capitalize">
                          
                            Dr. {appointment.employee[0].last_name} {appointment.employee[0].first_name}
                          
                        </h4>
                        <h5 className="doc-department">
                            {appointment.services[0].name}<br />
                        </h5>
                        <hr />
                        <h4 className="doc-name">
                          
                            {appointment.customer[0].last_name} {appointment.customer[0].first_name}
                          
                        </h4>
                        <h5 className="doc-department">
                            {appointment.customer[0].email}  
                        </h5>
                      </div>
                      <div className="booking-summary">
                        <div className="booking-item-wrap">
                          <hr />
                          <ul className="booking-date">
                            <li>
                              Data { appointment.start_date.substring(0, 10) }
                            </li>
                            <li>
                              Ora { appointment.appointment_time.substring(0, 5) }
                            </li>
                          </ul>
                          <hr />
                          <ul className="booking-fee">
                            <li> Note : { appointment.note } </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                </div>
                {/* /Appointment Tab */}
              </div>
              {/* Tab Content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

}