export default function UiSelectFile({id,title,handleFiles}){
    return <div>
        <label 
            className="form-label" 
            htmlFor={id}>
            {title}
        </label>
        <input 
            type="file" 
            className="form-control" 
            id={id} 
            name={id} 
            onChange={handleFiles}
        />   
    </div>
}