export default function UiTextArea( {id, label, value, onChange} ){

    return <div className="form-group">
        <label htmlFor={id}>
            {label}
        </label>
        <textarea 
            className="form-control" 
            type="text" 
            id={id} 
            name={id} 
            value={value}
            onChange={onChange}
            row="3"
        />
    </div>
}