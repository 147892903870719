import { useContext } from "react"
import { AuthContext } from "../../Contexts/Auth"

export default function ProfileSidebar(){

    const {user} = useContext(AuthContext)

    return <div className="profile-sidebar">
    <div className="widget-profile pro-widget-content">
      <div className="profile-info-widget">
        <div className="profile-det-info">
          <h3 className="text-capitalize">{user.username}</h3>
          <div className="patient-details">
            <h5>{user.email}</h5>
            <h5>{user.doctor}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
}