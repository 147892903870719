import Navbar from "../Navbar/navbar";

export default function Header(){
    return <div>

    <div className="header-top-mobile d-lg-none d-flex justify-content-center">
      <a href="tel:070480922" title="telefono" className="mr-3">
        <i className="fas fa-phone-volume top-icon" />
      </a>
      <a href="mailto:info@centromedicogenneruxi.it" title="email" className="mr-3">
        <i className="fas fa-envelope top-icon" />
      </a>
      <a href="https://www.centromedicogenneruxi.it/" title="sito web" className="mr-3">
        <i className="fas fa-globe top-icon" />
      </a>
    </div>

    <div className="header-top">
      <div className="left-top ">
        <ul>
          <li>
            <i className="fas fa-map-marker-alt top-icon" />V. Costantinopoli, 42 - CA
          </li>
          <li>
            <i className="fas fa-phone-volume top-icon" /><a href="tel:070491244" title="telefono">070 491244</a> - <a href="tel:070480922" title="telefono">070 480922</a>
          </li>
          <li>
            <i className="fas fa-envelope top-icon" /><a href="mailto:info@centromedicogenneruxi.it" title="email">info@centromedicogenneruxi.it</a>
          </li>
          <li>
            <i className="fas fa-globe top-icon" />
            <a href="https://www.centromedicogenneruxi.it/" title="sito web">
              Sito Web</a>
          </li>
        </ul>
      </div>
      <div className="right-top ">
        <ul>
          <li>
            <a href="https://www.facebook.com/centromedico.genneruxi.9/" className="fb"  title="facebook">
              <i className="fab fa-facebook-f top-icons" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/centromedicogenneruxi/" className="gp"  title="instagram">
              <i className="fab fa-instagram top-icons" />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <header className="header">

        <Navbar/>

    </header>

  </div>
}