import { useState } from "react";
import UiButton from "../../UI/UiButton/UiButton";
import UiDataTimePicker from "../../UI/UiDataTimePicker/UiDataTimePicker";
import UiFile from "../../UI/UiFile/UiFile";
import UiGroupCheckBox from "../../UI/UiGroupCheckBox/UiGroupCheckBox";
import UiGroupRadio from "../../UI/UiGroupRadio/UiGroupRadio";
import UiHeader from "../../UI/UiHeader/UiHeader";
import UiInput from "../../UI/UiInput/UiInput";
import UiSelect from "../../UI/UiSelect/UiSelect";
import UiTextArea from "../../UI/UiTextArea/UiTextArea";

export default function Form(){

    const [form,setForm] = useState({
        firstName : "",
        lastName : "",
        description : "",
        country : 1,
        city : 0,
        notificationsPush : [],
        notificationsType : null,
        files : []
    })

    const selectValues = [
        { id : 1 , name : 'Italia' },   
        { id : 2 , name : 'Francia' },     
    ]

    const selectItalia = [
        { id : 1 , name : 'Cagliari' },   
        { id : 2 , name : 'Roma' },  
        { id : 3 , name : 'Milano' }    
    ]

    const selectFrancia = [
        { id : 1 , name : 'Parigi' },   
        { id : 2 , name : 'Lille' },  
        { id : 3 , name : 'Nants' }    
    ]

    const [selectCity, setSelectCity] = useState(selectItalia)

    const uiGroupCheckBocValues = [
      {
        id : "comments",
        label : "Comments",
        description : "description comments",
        value :  "1"
      }, 
      {
        id : "comments2",
        label : "Comments2",
        description : "description comments2",
        value :  "2"
      }, 
      {
        id : "comments3",
        label : "Comments3",
        description : "description comments3",
        value :  "3"
      } 
    ]

    const uiGroupRadioValues = [
        {
          id : "comments",
          label : "Comments",
          description : "description comments",
          value :  "1"
        }, 
        {
          id : "comments2",
          label : "Comments2",
          description : "description comments2",
          value :  "2"
        }, 
        {
          id : "comments3",
          label : "Comments3",
          description : "description comments3",
          value :  "3"
        } 
      ]

    const setMyCountruy = (value) => {

        const c = (value === 1) ? selectItalia : selectFrancia
        setSelectCity(c)
        setForm({...form,country : value})    
    }  

    return <div className="content">
        <div className="container">
            <div className="row">
                <div className="col-lg col-md-12">
                    <h1>Custom Form</h1>
                    <form>
                        <UiHeader
                            title="Titolo"
                            subtitle="Sotto Titolo"
                        />  

                        <UiInput 
                            label="First Name"
                            id="firstname"
                            value={form.firstName}
                            onChange={(e) => { setForm({...form,firstName : e.target.value}) }}
                        />

                        <UiInput 
                            label="Last Name"
                            id="lastname"
                            value={form.lastName}
                            onChange={(e) => { setForm({...form,lastName : e.target.value}) }}
                        />

                        <UiTextArea
                             label="Description"
                             id="description"
                             value={form.description}
                             onChange={(e) => { setForm({...form,description : e.target.value}) }}
                        />

                        <UiSelect
                            label="Country"
                            id="country"
                            value={form.country}
                            values={selectValues}
                            onChange={(e) => { setMyCountruy(parseInt(e.target.value,0)) }}
                        />

                        <UiSelect
                            label="City"
                            id="city"
                            value={form.city}
                            values={selectCity}
                            onChange={(e) => { setForm({...form,city : parseInt(e.target.value,0)}) }}
                        />

                        <UiGroupCheckBox
                            title ="Title Group"
                            values={uiGroupCheckBocValues}
                            onChange={(selected) => { setForm({...form,notificationsType : selected}) }}
                        />

                        <UiGroupRadio    
                            title="Radio Group"  
                            name="push-notifications"
                            values={uiGroupRadioValues}
                            onChange={(e) => { setForm({...form,notificationsPush : parseInt(e.target.value,0)}) }}
                        />

                        <UiFile 
                            id="customFile"
                            title="Select file"
                            onAddFile={(f) => { setForm({...form,files : f}) }}
                        />

                        <UiButton
                            buttonClass="btn btn-primary"
                            caption="Salva"
                            onClick={(e) => alert("Dati salvati") }
                        />

                        <UiDataTimePicker/>
                            
                        <hr/>
                    </form>
                    <div>
                        <pre>
                            <code>
                                {JSON.stringify(form,undefined,2)}
                            </code>
                        </pre>
                    </div>
                </div>
            </div> 
        </div>          
    </div>
}