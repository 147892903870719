import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { ConfigContext } from "../../../Contexts/Config";
import Card from "../../UI/Card/card";

export default function Employee(){

    const arrDays = ["Domenica","Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];


    let {api_urls } = useContext(ConfigContext)

    let {slug} = useParams()

    const [employee, setEmployee] = useState(null)

    let shouldLog = useRef(true)

    useEffect(() => {
    
      if (shouldLog.current){
        shouldLog.current = false

        fetch(`${api_urls.cmg}/doctors/${slug}`)
        .then(response => response.json())
        .then((data) => {

            //console.log(data)

            setEmployee(data)
        })
      }
      
    }, []);

    return <div className="content">
    <div className="container">
      <div className="row">
        <div className="col-lg col-md-12">
          <div className="row blog-grid-row">
           {
                employee &&  (
                  <div className="col-12" key={employee.slug}>
                    <Card 
                        first_name={employee.first_name} 
                        last_name={employee.last_name}
                        slug={employee.slug}
                    />  
                  </div>
                )
            }  
          </div>
          {/* timetables Tab */}
          <div id="pat_appointments" className="tab-pane fade show active">
            <div className="card card-table mb-0">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>Giorno</th>
                        <th>Mattina</th>
                        <th>Sera</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      employee &&
                      employee.employee_timetables.map((timetables) => {
                          return(<tr key={timetables.id}>
                              <td>
                                  <span className="LabelGrid">Giorno</span>
                                  <span className="d-block text-info">{ arrDays[timetables.day] }</span>
                              </td>
                              <td>
                                  <span className="LabelGrid">Mattina</span>
                                  <span className="d-block text-info">
                                    {timetables.start_time_am.substring(0, 5)} - {timetables.finish_time_am.substring(0, 5)}
                                  </span>
                              </td>
                              <td>
                                  <span className="LabelGrid">Sera</span>
                                  <span className="d-block text-info">
                                    {timetables.start_time_pm.substring(0, 5)} - {timetables.finish_time_pm.substring(0, 5)}
                                  </span>
                              </td>
                              </tr> )
                          })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* /timetables Tab */}
        </div>
      </div>
    </div>
  </div>
}