import { useState } from "react"
import SignIn from "../../UI/SignIn/signIn"
import SignUp from "../../UI/SignUp/signUp"

export default function Sign(){

  const [isLogin, setIsLogin] = useState(true)
    
  return   <div className="content">
    <div className="container-fluid">

      <div className="row">
        <div className="col-md-4 offset-md-4">
          <div className="login-box">
            <div className="login-logo">
              <div className="login-logo text-center">
                <a href="https://cmg.centro-assistenza-informatica.it/admin">
                  <h3 className="text-primary">Area Riservata</h3>
                </a>
              </div>
            </div>

            {isLogin ? <SignIn/> : <SignUp/> }

          </div>
        </div>
      </div>
    </div>
  </div>
}