import { useContext, useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom";
import { ConfigContext } from "../../../Contexts/Config";
import Card from "../../UI/Card/card";
import Loader from "../../UI/Loader/loader";

export default function Search(){

    const [employees, setEmployees] = useState(null)

    const [departments, setDepartments] = useState(null)

    const [searched, setSearched] = useState("")

    const [lastPage, setLastPage] = useState("")

    const [selDepartment, setSelDepartment] = useState("")

    let {api_urls } = useContext(ConfigContext)

    let { num } = useParams()

    let shouldLogDepartments = useRef(true)

    // Departements
    useEffect(() => {
    
        if (shouldLogDepartments.current){
            shouldLogDepartments.current = false
  
          fetch(`${api_urls.cmg}/departements`)
          .then(response => response.json())
          .then((data) => {
  
              //console.log(data.data)
  
              setDepartments(data.data)
          })
        }
        
    }, []);

    // Search Employess
    useEffect(() => {

        setEmployees(null)
        setLastPage("")

        let page_limit = 100;
        let filters = "";
    
        let sUrl = `${api_urls.cmg}/employees?page_limit=${page_limit}&page=${num}`

        if (searched.length >= 3){
            filters += `&search=${searched}`;
            sUrl = `${api_urls.cmg}/employees?page=1${filters}`    
        }

        if (selDepartment.length >= 1){
            filters += `&department=${selDepartment}`;
            sUrl = `${api_urls.cmg}/employees?page=1${filters}`    
        }

        const timeSearch = setTimeout(() => {  
            
            //console.log(sUrl)

            fetch(sUrl)
            .then(response => response.json())
            .then((data) => {

                //git console.log(data)

                const sliceData = data.data.slice(0,page_limit)

                setEmployees(sliceData)

                setLastPage(data.last_page) 
            })
        },2000)  
        
        return () => {
            clearInterval(timeSearch)
        }

    },[searched,selDepartment, num])

  
    return <div className="content">
        <section className="section section-search">
            <div className="container-fluid">
                <div className="banner-wrapper">
                    <div className="banner-header text-center">
                        <h1>Ricerca del Medico</h1>
                    </div>          
                    <div className="search-box">

                        <form action="/home">
                            <div className="form-group search-location">
                                <select 
                                    className="form-control"
                                    onChange={(ev) => setSelDepartment(ev.target.value)}
                                    value={selDepartment}
                                >
                                    <option value="" key="0">-------</option>
                                    {
                                        departments &&  departments.map (el => {
                                            return <option value={el.slug} key={el.id}>{el.name}</option>
                                        })
                                    }        
                                </select>
                                <span className="form-text">Specializzazione</span>
                            </div>

                            <div className="form-group search-info">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    onChange={(ev) => setSearched(ev.target.value)}
                                    value={searched}
                                />
                                <span className="form-text">Cognome o nome del medico {searched}</span>
                            </div>

                           
                        </form>
                    </div>
                    
                </div>
            </div>
        </section>
        <div className="container">  
            <div className="row">
                <div className="col-lg col-md-12">
                    <div className="row blog-grid-row">
                        {
                        employees &&  employees.map (el => {
                            return <div className="col-md-4" key={el.id}>
                                <Card 
                                    first_name={el.first_name} 
                                    last_name={el.last_name}
                                    slug={el.slug}
                                />    
                            </div>
                        })
                        }  
                    </div>
                </div>
            </div>

            { !employees && <div div="row">
                    <div className="col-12 d-flex justify-content-center my-4">
                        <Loader/>
                    </div>
                </div> 
            }            
           

            {  !searched &&
            <div div="row">
                <ul className="pagination pagination-lg justify-content-end mt-5">
                { num > 1 && <li className="page-item" >
                    <Link 
                    className="page-link rounded-0 mr-3 shadow-sm border-top-0 border-left-0 text-dark" 
                    to={`/search/${+num-1}`} >
                        PREC
                    </Link>
                </li>
                }
                { num < lastPage && <li className="page-item">
                    <Link 
                    className="page-link rounded-0 mr-3 shadow-sm border-top-0 border-left-0 text-dark" 
                    to={`/search/${+num+1}`}>
                        SUCC
                    </Link>
                </li>
                }
                </ul>
            </div>
            }

        </div>
  </div>
}