import { useEffect, useRef, useState } from "react"
import { useContext } from "react";
import { ConfigContext } from "../../../Contexts/Config";
import Card from "../Card/card";
import Loader from "../Loader/loader";

export default function Featured(){

    const [featured, setFeatured] = useState(null)

    let {api_urls } = useContext(ConfigContext)

    let shouldLog = useRef(true)

    useEffect(() => {
    
      if (shouldLog.current){
        shouldLog.current = false

        fetch(`${api_urls.cmg}/employees`)
        .then(response => response.json())
        .then((data) => {

            //console.log(data.data)

            const sliceData = data.data.slice(0,6)

            setFeatured(sliceData)
        })
      }
      
    }, []);

    return <div className="content">
    <div className="container">
      <div className="row">
        <div className="col-lg col-md-12">
          <div className="row blog-grid-row">
           {
                featured &&  featured.map (el => {
                    return <div className="col-md-4" key={el.id}>
                        <Card 
                            first_name={el.first_name} 
                            last_name={el.last_name}
                            slug={el.slug}
                        />   
                    </div>
                })
            }  
          </div>
        </div>
      </div>
      { !featured && <div div="row">
              <div className="col-12 d-flex justify-content-center my-4">
                  <Loader/>
              </div>
          </div> 
      }    
    </div>
  </div>
}