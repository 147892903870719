export default function UiRadio( {name, id, label, value, onChange, description} ){

    return <div className="form-check">
            <input 
                type="radio" 
                className="form-check-input" 
                id={id} 
                name={name} 
                value={value}
                onChange={onChange}
                />
            <label 
                className="form-check-label" 
                htmlFor={id}>
                {label}
            </label>
            <p>{description}</p>
        </div>
    

}