import { Link } from "react-router-dom";

export default function Card(props){
    return  <div className="blog grid-blog">
                <div className="blog-image">
                    <Link to={`/doctor/${props.slug}`} title={`${props.first_name}-${props.last_name}`} >
                        <img className="img-fluid border border-primary" 
                             src={`https://cmg.centro-assistenza-informatica.it/assets/img/doctors/${props.first_name.replace(" ","-")}-${props.last_name}.jpg`}
                             alt={`${props.first_name}-${props.last_name}`}
                        />
                    </Link>
                </div>
                <div className="blog-content">
                    <h3 className="blog-title">
                        <Link to={`/doctor/${props.slug}`}>
                        Dr. {props.first_name} {props.last_name}
                        </Link>
                        <p className="doc-department">  
                        {props.slug}    
                        </p>
                    </h3>                           
                    <div className="clinic-booking">
                        <a href="/">
                        Richiedi Visita
                        </a>
                    </div>                        
                </div>
            </div>    
}