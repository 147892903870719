import Featured from "../../UI/Featured/featured";
import Info from "../../UI/Info/info";

export default function Home(){

    return  <div className="main-wrapper">

        <Info/>

        <Featured/>
   
    </div>
}    