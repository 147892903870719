export default function Info(){
    return <div className="content">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12">
     
          <div className="blog">
            <h1 className="blog-title">Eccellenze Professionali</h1>
            <div className="blog-content">
              <p>Il fiore all'occhiello del centro è la presenza di numerose eccellenze professionali che hanno maturato la loro esperienza ai vertici della sanità cittadina. Il loro  aggiornamento continuo e la tecnologia avanzata consentono di fornire ai pazienti risposte chiare e rassicuranti, con costi contenuti, per garantire a tutti "una buona sanità " .</p>
            </div>
          </div>
  
        </div>            
      </div>
    </div> 
  </div>   
}