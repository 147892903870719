import { useState, createContext } from "react";

export const SearchAppointmentsContext = createContext()

export function SearchAppointmentsProvider(props) {

    const initialSearch = localStorage.getItem('searchAppointments')

    const [searchAppointments, setSearchAppointments] = useState(JSON.parse(initialSearch))

    const filterAppointments = (software, selEmployee, dateFrom, dateTo, status) =>{

        const obj = {
            software: software,
            selEmployee: selEmployee,
            dateFrom: dateFrom,
            dateTo : dateTo,
            status : status
        }

        setSearchAppointments(obj)
        localStorage.setItem('searchAppointments',JSON.stringify(obj))
    }

    const resetAppointments = () => {   
            localStorage.removeItem('searchAppointments')
            setSearchAppointments(null)
    }


    return(
        <SearchAppointmentsContext.Provider value={{ searchAppointments, filterAppointments, resetAppointments }}>
            {props.children}
        </SearchAppointmentsContext.Provider>    
    )
}

