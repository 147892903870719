export default function UiListFiles({files, handleRemoveFile}){

    console.log(files)

    return <div>
        {files && (
            <ul>
                {files.map((file) => (
                <li
                    key={file.name}
                >
                    {file.name}  
                    <button 
                        className="btn btn-danger m-2"
                        onClick={()=> handleRemoveFile(file.name)}
                    > Remove
                    </button>  
                </li>
                ))}
            </ul>
        )}
    </div>
}