import { AuthContext } from "../../../Contexts/Auth"
import { useContext } from "react"
export default function Profile(){

    const {user} = useContext(AuthContext)

    return <div className="container">
        <div className="row">
            <div className="col-12 mb-6' min-vh-100">
                <h3>{user.username}</h3>
            </div>
        </div>
    </div>
}