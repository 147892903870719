import { useEffect, useState } from "react";
import UiCheckBox from "../UiCheckBox/UiCheckBox";

export default function UiGroupCheckBox({title, values, onChange}){

    const [selected, setSelected] = useState([])

    useEffect(() => {
        onChange(selected)
    }, [selected])

    const hadleChange = (e) =>{
        const val = e.target.value
        const checked = e.target.checked

        if(!checked){
            setSelected([...selected.filter((i) => i !== parseInt(val,0))])
        } else {
            setSelected([...selected,parseInt(val,0)])
        }
    }

    return <div>
        {title}
        {
            values.map((checkbox,idx) =>{
                return  <UiCheckBox
                key={idx}
                id = {checkbox.id}
                label = {checkbox.label}
                description = {checkbox.description}
                value= {checkbox.value}
                onChange={hadleChange}
            />
            })
        }
    </div>
}