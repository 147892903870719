import { useEffect, useState } from "react"
import UiListFiles from "../UiListFiles/UiListFiles"
import UiSelectFile from "../UiSelectFile/UiSelectFile"

export default function UiFile({id, title, onAddFile}){

    const [files,setFiles] = useState([])

    useEffect(() => {
        onAddFile(files)
    }, [files])

    const handleFiles = (e) =>{
        const fileList = e.target.files

        setFiles([...files, ...Array.from(fileList)])
    }

    const handleRemoveFile = (name) =>{
        const newFiles = files.filter((file) => file.name !== name)

        setFiles(newFiles)
    }

    return <div>
        <UiSelectFile
             id={id} 
             title={title} 
             handleFiles={handleFiles}
        />
        <UiListFiles
            files = {files}
            handleRemoveFile={handleRemoveFile}
        />
         
    </div>
}