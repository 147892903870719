import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Contexts/Auth";
import { ConfigContext } from "../../../Contexts/Config";
import useInput from "../../../Hooks/useInput";

export default function SignIn(){

  let navigate = useNavigate();

  let { login } = useContext(AuthContext)

  let {api_urls} = useContext(ConfigContext)

  const email = useInput("");
  const password = useInput("");

  const [loading, setLoading] = useState(false)
  const [errorLogin, setErrorLogin] = useState(false)

  const signIn = (event) =>{

      event.preventDefault()

      setLoading(true)
      setErrorLogin(false)

      fetch(
        `${api_urls.cmg}/users/login`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email : email.value, 
            password : password.value
          }),
        })
        .then(res => res.json())
        .then(data =>{
          if(data.success === true){
            const token = data.token
            
            fetch(
              `${api_urls.cmg}/user/profile`,{
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then(res => res.json())
              .then(data =>{
                login(data.data.name, token, data.data.id, data.data.admin, data.data.email, data.doctor)
                navigate(`/`);
              })  

          } else {
            setErrorLogin(true)
            setLoading(false)
            //console.log(data.message)
          }
        })
    }

    return <>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">
              Login
            </p>
            <form onSubmit={signIn}>
              
              <div className="form-group">
                <input id="userMail" type="email" className="form-control" required autoComplete="email" placeholder="Email"
                  {...email}
                />
              </div>
              <div className="form-group">
                <input id="userPassword" type="password" className="form-control" name="password" required placeholder="Password" 
                  {...password}
                />
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                  { errorLogin &&
                    <span className="text-danger">Email o password errate !</span>
                  }
                  </div>
                </div>

                { !loading &&
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block btn-flat">
                    Login
                  </button>
                </div>
                }

              </div>
            </form>
          </div>
        </div>      
    </>
}