import './App.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { AuthProvider } from './Contexts/Auth';
import { ConfigProvider } from './Contexts/Config';
import { SearchAppointmentsProvider } from './Contexts/Search';

import Footer from './Components/UI/Footer/footer';
import Header from './Components/UI/Header/header';
import Home from './Components/Views/Home/home';
import Search from './Components/Views/Search/Search';
import Employee from './Components/Views/Employee/employee';
import Sign from './Components/Views/Sign/sign';
import Profile from './Components/Views/Profile/profile';
import Appointments from './Components/Views/Appointments/Appointments';
import Appointment from './Components/Views/Appointment/Appointment';
import Form from './Components/Views/Form/form';

function App() {
  return (
    <ConfigProvider>
      <AuthProvider>
        
        <Router>

          <Header/>

          <Routes>

            <Route path="/" element={<Home />} />

            <Route 
              path="/search/:num" 
              element={<Search/>} 
            />

            <Route path="/doctor/:slug"  element={<Employee />} />

            <Route path="/sign"  element={<Sign />} />

            <Route path="/profile"  element={<Profile />} />
                        
            <Route 
                path="/appointments"  
                element={
                  <SearchAppointmentsProvider>
                    <Appointments />
                  </SearchAppointmentsProvider>
                } 
            />  
                      
            <Route path="/appointments/:id"  element={<Appointment />} />

            <Route path="/custom-form" element={<Form />} />

          </Routes>

          <Footer/>

        </Router>
       
      </AuthProvider>
    </ConfigProvider> 
  );
}

export default App;
